import { arraySchema, idSchema, nativeEnumSchema, numberSchema, stringSchema, zObject } from '@/schemas'
import { EventBillingTypeEnum, EventStatusEnum, EventVisitTypeEnum } from '@/types'

export const calendarSchema = zObject({
  start_at: stringSchema.datetime(),
  end_at: stringSchema.datetime(),
  location_id: numberSchema,
  patient_id: idSchema,
  bill_type: nativeEnumSchema(EventBillingTypeEnum),
  visit_type: nativeEnumSchema(EventVisitTypeEnum),
  status: nativeEnumSchema(EventStatusEnum),
  participants: arraySchema(stringSchema),
})
