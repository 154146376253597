import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueDOMPurifyHTML from 'vue-dompurify-html'

import auth0 from '@/core/auth'
import { initSentry } from '@/plugins'

import App from './App.vue'
import router from './router'

import './assets/styles/index.css'

const app = createApp(App)
initSentry(app, router)

const pinia = createPinia()
app.use(pinia)

app.use(auth0)
app.use(router)
app.use(VueDOMPurifyHTML)

app.mount('#app')
