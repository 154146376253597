import { BaseLibType, PatientType } from '@/types'
import { calendarSchema, ZInferType } from '@/schemas'

type CalendarApi = {
  prev: () => void
  next: () => void
  today: () => void
  gotoDate: (date: Date | number) => void
  changeView: (viewName: string) => void
}

export type CalendarRef = {
  getApi: () => CalendarApi
}

export type CalendarDirection = 'prev' | 'next' | 'today'
export type CalendarViewType = 'day' | 'month' | 'year'

export type ChangeDatePayload = {
  value: string
  type: CalendarViewType
}

export enum CalendarViewEnum {
  MONTH = 'dayGridMonth',
  WEEK = 'timeGridWeek',
  DAY = 'timeGridDay',
}

export type WorkHour = {
  day_of_week: string
  start_time: string
  end_time: string
  index?: number
}

export type Unavailability = {
  start_time: string
  end_time: string
}

export type CalendarSettings = {
  work_hours: WorkHour[]
  unavailability: Unavailability[]
}

export type ViewedDates = {
  start: Date
  end: Date
  startStr: string
  endStr: string
  timeZone: string
}

export type LocationType = BaseLibType & {
  work_hours: WorkHour[]
  unavailability: Unavailability[]
}

export type DoctorType = BaseLibType & {
  email: string
  work_hours: WorkHour[]
  unavailability: Unavailability[]
}

export type CalendarEventType = {
  id: string
  title: string
  start_at: string
  end_at: string
  location: LocationType
  patient: PatientType
  participants: DoctorType[]
  bill_type: EventBillingTypeEnum
  visit_type: EventVisitTypeEnum
  status: EventStatusEnum
}

export type CalendarEventWithInfoType = {
  clinic: LocationType
  events: CalendarEventType[]
  doctors: DoctorType[]
}

export type CalendarEventFormType = ZInferType<typeof calendarSchema>

export enum EventBillingTypeEnum {
  INSURANCE = 'insurance',
  UNSURE = 'unsure',
  NON_BILLABLE = 'non_billable',
  ELECTIVE = 'elective',
}

export enum EventVisitTypeEnum {
  VIRTUAL_CONSULTATION = 'virtual_consultation',
  VIRTUAL_FOLLOW_UP = 'virtual_follow_up',
  PHONE_CONSULTATION = 'phone_consultation',
  CONSULTATION = 'consultation',
  PRE_TREATMENT = 'pre_treatment',
  PROCEDURE_TREATMENT = 'procedure_treatment',
  FOLLOW_UP = 'follow_up',
}

export enum EventStatusEnum {
  SCHEDULED = 'scheduled',
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
  MESSAGE_LEFT = 'message_left',
  WAITLISTED = 'waitlisted',
  NO_SHOW = 'no_show',
  RESERVED = 'reserved',
  RESCHEDULED = 'rescheduled',
}
