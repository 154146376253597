import { createWebHistory, createRouter } from 'vue-router'
import { useAuth0 } from '@auth0/auth0-vue'
import { watchEffect } from 'vue'

import { HOME_PAGE } from '@/constants'

const routes = [
  {
    path: '/patients',
    meta: { breadcrumb: 'Patients' },
    children: [
      {
        path: '',
        component: () => import('@/pages/patient-list/PatientsListPage.vue'),
      },
      {
        path: ':id',
        component: () => import('@/pages/patient/PatientViewPage.vue'),
        meta: { breadcrumb: 'Patient Details' },
      },
    ],
  },
  {
    path: '/messages',
    component: () => import('@/pages/messages/MessagesViewPage.vue'),
    meta: { breadcrumb: 'Messages' },
  },
  { path: '/callback', name: 'Callback', component: () => import('@/pages/auth/AuthCallback.vue') },
  { path: '/:pathMatch(.*)*', name: 'NotFound', redirect: HOME_PAGE },
  {
    path: '/appointments',
    name: 'Appointments',
    component: () => import('@/pages/appointments/AppointmentsViewPage.vue'),
    meta: { breadcrumb: 'Appointments' },
  },
  {
    path: '/user-roles',
    component: () => import('@/pages/user-roles/UserRoleViewPage.vue'),
    meta: { breadcrumb: 'User Roles' },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0() || {}

  await new Promise(resolve => {
    watchEffect(() => {
      if (!isLoading.value) resolve(null)
    })
  })

  if (to.name === 'Callback') {
    if (to.query.error) {
      await loginWithRedirect({
        appState: { targetUrl: from.fullPath },
      })
      return next(false)
    }

    return next()
  }

  if (!isAuthenticated.value) {
    await loginWithRedirect({
      authorizationParams: {
        prompt: 'none',
      },
      appState: { targetUrl: to.fullPath },
    })
    return next(false)
  }

  if (to.name === 'NotFound') {
    return next(HOME_PAGE)
  }

  next()
})

export default router
